<ng-container *transloco="let t; read: 'grouped-typeahead'">
  <form [formGroup]="typeaheadForm" class="grouped-typeahead">
    <div class="typeahead-input" [ngClass]="{'focused': hasFocus}" (click)="onInputFocus($event)">
      <div class="search">
        <input #input [id]="id" type="text" inputmode="search" autocomplete="off" formControlName="typeahead" [placeholder]="placeholder"
               aria-haspopup="listbox" aria-owns="dropdown"
               [attr.aria-expanded]="hasFocus && hasData"
               aria-autocomplete="list" (focusout)="close($event)" (focus)="open($event)" role="searchbox"
        >
        @if (searchTerm.length > 0) {
          @if (isLoading) {
            <div class="spinner-border spinner-border-sm" role="status">
              <span class="visually-hidden">{{t('loading')}}</span>
            </div>
          } @else {
            <button type="button" class="btn-close" [attr.aria-label]="t('close')" (click)="resetField()"></button>
          }
        }
      </div>
    </div>
      @if (hasFocus) {
      <div class="overlay">
        <div class="dropdown">
          <ul class="list-group" role="listbox" id="dropdown">

            @if (seriesTemplate !== undefined && groupedData.series.length > 0) {
              <li class="list-group-item section-header"><h5 id="series-group">Series</h5></li>
              <ul class="list-group results" role="group" aria-describedby="series-group">
                @for(option of groupedData.series; track option; let index = $index) {
                  <li (click)="handleResultClick(option)" tabindex="0"
                      class="list-group-item" aria-labelledby="series-group" role="option">
                    <ng-container [ngTemplateOutlet]="seriesTemplate" [ngTemplateOutletContext]="{ $implicit: option, idx: index }"></ng-container>
                  </li>
                }
              </ul>
            }


            @if (collectionTemplate !== undefined && groupedData.collections.length > 0) {
              <li class="list-group-item section-header"><h5>{{t('collections')}}</h5></li>
              <ul class="list-group results">
                @for(option of groupedData.collections; track option; let index = $index) {
                  <li (click)="handleResultClick(option)" tabindex="0"
                      class="list-group-item" role="option">
                    <ng-container [ngTemplateOutlet]="collectionTemplate" [ngTemplateOutletContext]="{ $implicit: option, idx: index }"></ng-container>
                  </li>
                }
              </ul>
            }


            @if (readingListTemplate !== undefined && groupedData.readingLists.length > 0) {
              <li class="list-group-item section-header"><h5>{{t('reading-lists')}}</h5></li>
              <ul class="list-group results">
                @for(option of groupedData.readingLists; track option; let index = $index) {
                  <li (click)="handleResultClick(option)" tabindex="0"
                      class="list-group-item" role="option">
                    <ng-container [ngTemplateOutlet]="readingListTemplate" [ngTemplateOutletContext]="{ $implicit: option, idx: index }"></ng-container>
                  </li>
                }
              </ul>
            }

            @if (bookmarkTemplate !== undefined && groupedData.bookmarks.length > 0) {
              <li class="list-group-item section-header"><h5>{{t('bookmarks')}}</h5></li>
              <ul class="list-group results">
                @for(option of groupedData.bookmarks; track option; let index = $index) {
                  <li (click)="handleResultClick(option)" tabindex="0"
                      class="list-group-item" role="option">
                    <ng-container [ngTemplateOutlet]="bookmarkTemplate" [ngTemplateOutletContext]="{ $implicit: option, idx: index }"></ng-container>
                  </li>
                }
              </ul>
            }


            @if (libraryTemplate !== undefined && groupedData.libraries.length > 0) {
              <li class="list-group-item section-header"><h5 id="libraries-group">{{t('libraries')}}</h5></li>
              <ul class="list-group results" role="group" aria-describedby="libraries-group">
                @for(option of groupedData.libraries; track option; let index = $index) {
                  <li (click)="handleResultClick(option)" tabindex="0"
                      class="list-group-item" aria-labelledby="libraries-group" role="option">
                    <ng-container [ngTemplateOutlet]="libraryTemplate" [ngTemplateOutletContext]="{ $implicit: option, idx: index }"></ng-container>
                  </li>
                }
              </ul>
            }

            @if (genreTemplate !== undefined && groupedData.genres.length > 0) {
              <li class="list-group-item section-header"><h5>{{t('genres')}}</h5></li>
              <ul class="list-group results">
                @for(option of groupedData.genres; track option; let index = $index) {
                  <li (click)="handleResultClick(option)" tabindex="0"
                      class="list-group-item" role="option">
                    <ng-container [ngTemplateOutlet]="genreTemplate" [ngTemplateOutletContext]="{ $implicit: option, idx: index }"></ng-container>
                  </li>
                }
              </ul>
            }

            @if (tagTemplate !== undefined && groupedData.tags.length > 0) {
              <li class="list-group-item section-header"><h5>{{t('tags')}}</h5></li>
              <ul class="list-group results">
                @for(option of groupedData.tags; track option; let index = $index) {
                  <li (click)="handleResultClick(option)" tabindex="0"
                      class="list-group-item" role="option">
                    <ng-container [ngTemplateOutlet]="tagTemplate" [ngTemplateOutletContext]="{ $implicit: option, idx: index }"></ng-container>
                  </li>
                }
              </ul>
            }

            @if (personTemplate !== undefined && groupedData.persons.length > 0) {
              <li class="list-group-item section-header"><h5>{{t('people')}}</h5></li>
              <ul class="list-group results">
                @for(option of groupedData.persons; track option; let index = $index) {
                  <li (click)="handleResultClick(option)" tabindex="0"
                      class="list-group-item" role="option">
                    <ng-container [ngTemplateOutlet]="personTemplate" [ngTemplateOutletContext]="{ $implicit: option, idx: index }"></ng-container>
                  </li>
                }
              </ul>
            }

            @if (chapterTemplate !== undefined && groupedData.chapters.length > 0) {
              <li class="list-group-item section-header"><h5>{{t('chapters')}}</h5></li>
              <ul class="list-group results">
                @for(option of groupedData.chapters; track option; let index = $index) {
                  <li (click)="handleResultClick(option)" tabindex="0"
                      class="list-group-item" role="option">
                    <ng-container [ngTemplateOutlet]="chapterTemplate" [ngTemplateOutletContext]="{ $implicit: option, idx: index }"></ng-container>
                  </li>
                }
              </ul>
            }

            @if (fileTemplate !== undefined && groupedData.files.length > 0) {
              <li class="list-group-item section-header"><h5>{{t('files')}}</h5></li>
              <ul class="list-group results">
                @for(option of groupedData.files; track option; let index = $index) {
                  <li (click)="handleResultClick(option)" tabindex="0"
                      class="list-group-item" role="option">
                    <ng-container [ngTemplateOutlet]="fileTemplate" [ngTemplateOutletContext]="{ $implicit: option, idx: index }"></ng-container>
                  </li>
                }
              </ul>
            }

            @if (!hasData && searchTerm.length > 0 && !isLoading) {

              <ul class="list-group results">
                <li class="list-group-item">
                  <ng-container [ngTemplateOutlet]="noResultsTemplate"></ng-container>
                </li>
              </ul>
            }

            @if (searchTerm.length > 0 && !isLoading) {
              <li class="list-group-item" style="min-height: 34px" (click)="$event.stopPropagation()">
                <ng-container [ngTemplateOutlet]="extraTemplate"></ng-container>
                <form [formGroup]="searchSettingsForm">
                  <div class="form-check form-switch">
                    <input type="checkbox" id="search-include-extras" role="switch" formControlName="includeExtras" class="form-check-input"
                          aria-labelledby="auto-close-label" aria-describedby="tag-promoted-help">
                    <label class="form-check-label" for="search-include-extras">{{t('include-extras')}}</label>
                  </div>
                </form>
              </li>
            }
          </ul>
        </div>
      </div>
    }

  </form>

</ng-container>
